import { ref, reactive, nextTick, watch } from 'vue';
import moment, { Moment } from 'moment';
import { qmOptions } from '@/views/SalesReporting/type';
import { message } from 'ant-design-vue';
import axios from 'axios';
import { useStore } from 'vuex';
import {
    getBrandByBuID,
    getPayoutRoundList,
    getPayoutFormByModelList,
} from '@/API/salesReporting';
interface SearchParams {
    bu: string | null;
    brand: string | null;
    payoutRound: string | null;
    year: string | null;
    quarter: Array<any>;
}
interface TableData {
    region: string;
    dealerGroup: string;
    dealerCode: string;
    dealerName: string;
}
const useOEMPrepaymentQualified = () => {
    const searchParams = reactive<SearchParams>({
        bu: null,
        brand: null,
        payoutRound: null,
        year: null,
        quarter: [],
    });

    const setMonth = () => {
        const quarter = moment().quarter();
        if (quarter == 1) {
            searchParams.quarter = ['01', '02', '03'];
        }
        if (quarter == 2) {
            searchParams.quarter = ['04', '05', '06'];
        }
        if (quarter == 3) {
            searchParams.quarter = ['07', '08', '09'];
        }
        if (quarter == 4) {
            searchParams.quarter = ['10', '11', '12'];
        }
    };

    const store = useStore();
    const buList = store.state.user.buList.filter((bu: any) => {
        return bu.parentId === null;
    });
    const buDefault = ref('');
    const brandList = ref<any[]>([]);
    buDefault.value = buList[0].id;
    for (let i = 0; i < buList.length; i++) {
        if ('MBPC' === buList[i].nameEn) {
            buDefault.value = buList[i].id;
        }
    }

    searchParams.bu = buDefault.value;
    getBrandByBuID({ buIds: searchParams.bu }).then((res) => {
        const brands = res[0].brandList;
        brands.push({ id: 0, brandNameEn: 'All' });
        brandList.value = brands;
    });
    searchParams.year = moment().format('YYYY');
    setMonth();
    const downloadTableData = [
        {
            file: 'Raw Data',
            sign: 2,
        },
        {
            file: 'Report Data',
            sign: 1,
        },
    ];
    const activeTab = ref('unQulified');
    const notDemoCarList = ref<any[]>([]);
    const demoCarList = ref<any[]>([]);

    const columns = [
        {
            title: 'Eligible Vehicle',
            dataIndex: 'typeClass',
            width: 150,
            align: 'left',
        },
        {
            title: 'Program Master Code',
            dataIndex: 'programCode',
            width: 190,
            align: 'left',
        },
        {
            title: 'Program Name',
            dataIndex: 'programName',
            width: 150,
            align: 'left',
        },
        {
            title: 'Time Period(MM/DD/YYYY)',
            dataIndex: 'timePeriod',
            width: 200,
            align: 'left',
        },
        {
            title: 'Estimated Budget',
            dataIndex: 'estimatedBudget',
            width: 150,
            align: 'right',
        },
        {
            title: 'Previously Paid out(w/o VAT)',
            dataIndex: 'previouslyPaidOutWOVat',
            width: 200,
            align: 'right',
        },
        {
            title: 'Total Payout Current Payout(w/o VAT)',
            dataIndex: 'totalPayoutWOVATC',
            width: 300,
            align: 'right',
        },
        {
            title: 'Total Payout (w/o VAT)',
            dataIndex: 'totalPayoutWOVAT',
            width: 200,
            align: 'right',
        },
        {
            title: 'Gap',
            dataIndex: 'gap',
            width: 100,
            align: 'right',
        },
        {
            title: 'Previously Paid out(w VAT)',
            dataIndex: 'previouslyPaidOutWVat',
            width: 220,
            align: 'right',
        },
        {
            title: 'Total Payout Current Payout(w VAT)',
            dataIndex: 'totalPayoutWVATC',
            width: 300,
            align: 'right',
        },
        {
            title: 'Total Payout (w VAT)',
            dataIndex: 'totalPayoutWVAT',
            width: 150,
            align: 'right',
        },
    ];

    const doSearch = () => {
        const array: Array<string> = [];
        for (let i = 0; i < searchParams.quarter.length; i++) {
            if (searchParams.quarter[i][0] == 'Q') {
                const quarter = parseInt(searchParams.quarter[i][1]);
                if (quarter == 4) {
                    array.push('' + ((quarter - 1) * 3 + 1));
                    array.push('' + ((quarter - 1) * 3 + 3));
                    array.push('' + ((quarter - 1) * 3 + 2));
                } else {
                    array.push('0' + ((quarter - 1) * 3 + 1));
                    array.push('0' + ((quarter - 1) * 3 + 3));
                    array.push('0' + ((quarter - 1) * 3 + 2));
                }
            } else {
                array.push(searchParams.quarter[i]);
            }
        }

        let buname = '';

        for (let i = 0; i < buList.length; i++) {
            if (searchParams.bu === buList[i].id) {
                buname = buList[i].nameEn;
            }
        }
        const params = {
            bu: buname,
            brand: searchParams.brand === 'All' ? '' : searchParams.brand,
            month: array,
            year: searchParams.year,
        };
        getPayoutFormByModelList(params).then((data) => {
            console.log(data);
            notDemoCarList.value = [];
            demoCarList.value = [];
            for (let i = 0; i < data.noDemoCarList.length; i++) {
                const one = data.noDemoCarList[i];
                notDemoCarList.value.push({
                    typeClass: one.typeClass,
                    programCode: one.programCode,
                    programName: one.programName,
                    timePeriod: one.beginDate + '-' + one.endDate,
                    estimatedBudget: one.estimatedBudget,
                    previouslyPaidOutWOVat: '￥' + one.previouslyPaidOutWOVat,
                    totalPayoutWOVAT: '￥' + one.totalPayoutWOVAT,
                    totalPayoutWOVATC: '￥' + one.amountWOVat,
                    gap: '￥' + one.gap,
                    previouslyPaidOutWVat: '￥' + one.previouslyPaidOutWVat,
                    totalPayoutWVATC: '￥' + one.amountWVat,
                    totalPayoutWVAT: '￥' + one.totalPayoutWVAT,
                });
            }
            if (data.totalList1.length > 0) {
                notDemoCarList.value.push({
                    typeClass: data.totalList1[0][0],
                    programCode: data.totalList1[0][1],
                    programName: data.totalList1[0][2],
                    timePeriod: data.totalList1[0][5],
                    estimatedBudget: data.totalList1[0][6],
                    previouslyPaidOutWOVat: '￥' + data.totalList1[0][7],
                    totalPayoutWOVATC: '￥' + data.totalList1[0][8],
                    totalPayoutWOVAT: '￥' + data.totalList1[0][9],
                    gap: '￥' + data.totalList1[0][10],
                    previouslyPaidOutWVat: '￥' + data.totalList1[0][11],
                    totalPayoutWVATC: '￥' + data.totalList1[0][12],
                    totalPayoutWVAT: '￥' + data.totalList1[0][13],
                });
            }
            for (let i = 0; i < data.demoCarList.length; i++) {
                const one = data.demoCarList[i];
                demoCarList.value.push({
                    typeClass: one.typeClass,
                    programCode: one.programCode,
                    programName: one.programName,
                    timePeriod: one.beginDate + '-' + one.endDate,
                    estimatedBudget: one.estimatedBudget,
                    previouslyPaidOutWOVat: '￥' + one.previouslyPaidOutWOVat,
                    totalPayoutWOVAT: '￥' + one.totalPayoutWOVAT,
                    totalPayoutWOVATC: '￥' + one.amountWOVat,
                    gap: '￥' + one.gap,
                    previouslyPaidOutWVat: '￥' + one.previouslyPaidOutWVat,
                    totalPayoutWVATC: '￥' + one.amountWVat,
                    totalPayoutWVAT: '￥' + one.totalPayoutWVAT,
                });
            }
            if (data.totalList2.length > 0) {
                demoCarList.value.push({
                    typeClass: data.totalList2[0][0],
                    programCode: data.totalList2[0][1],
                    programName: data.totalList2[0][2],
                    timePeriod: data.totalList2[0][5],
                    estimatedBudget: data.totalList2[0][6],
                    previouslyPaidOutWOVat: '￥' + data.totalList2[0][7],
                    totalPayoutWOVATC: '￥' + data.totalList2[0][8],
                    totalPayoutWOVAT: '￥' + data.totalList2[0][9],
                    gap: '￥' + data.totalList2[0][10],
                    previouslyPaidOutWVat: '￥' + data.totalList2[0][11],
                    totalPayoutWVATC: '￥' + data.totalList2[0][12],
                    totalPayoutWVAT: '￥' + data.totalList2[0][13],
                });
            }
        });
    };

    const searchClick = () => {
        if (!searchParams.bu) {
            message.error('Please select BU');
            return;
        }

        if (!searchParams.year) {
            message.error('Please select Year');
            return;
        }
        let sign = true;

        const array: Array<string> = [];
        for (let i = 0; i < searchParams.quarter.length; i++) {
            if (searchParams.quarter[i][0] == 'Q') {
                const quarter = parseInt(searchParams.quarter[i][1]);
                if (quarter == 4) {
                    array.push('' + ((quarter - 1) * 3 + 1));
                    array.push('' + ((quarter - 1) * 3 + 3));
                    array.push('' + ((quarter - 1) * 3 + 2));
                } else {
                    array.push('0' + ((quarter - 1) * 3 + 1));
                    array.push('0' + ((quarter - 1) * 3 + 3));
                    array.push('0' + ((quarter - 1) * 3 + 2));
                }
            } else {
                array.push(searchParams.quarter[i]);
            }
        }

        const arr = array.sort();
        if (array.length) {
            const first = parseInt(arr[0]);
            for (let i = 1; i < arr.length; i++) {
                if (first + i != parseInt(arr[i])) {
                    sign = false;
                }
            }
        }
        if (!sign) {
            message.error('Month must be contacted');
            return;
        }

        // if (!searchParams.payoutRound){
        //   message.error('Please select PayoutRound')
        //   return
        // }
        doSearch();
    };

    const resetClick = () => {
        Object.assign(searchParams, {
            bu: buDefault.value,
            brand: undefined,
            payoutRound: undefined,
            year: moment().format('YYYY'),
            quarter: [],
        });
        setMonth();
        console.log(
            '!(brandList.length && searchParams.bu)',
            !(brandList.value.length && searchParams.bu),
            'brandList.value.length',
            brandList.value.length,
            'searchParams.bu',
            searchParams.bu
        );
        // searchParams.bu = buDefault.value;
    };
    const downloadType = ref(2);
    const downloadTypeVisible = ref(false);
    const downloadClick = () => {
        if (!searchParams.bu) {
            message.error('Please select BU');
            return;
        }

        if (!searchParams.year) {
            message.error('Please select Year');
            return;
        }
        let sign = true;
        const arr = searchParams.quarter.sort();
        if (searchParams.quarter.length) {
            const first = parseInt(arr[0]);
            for (let i = 1; i < arr.length; i++) {
                if (first + i != parseInt(arr[i])) {
                    sign = false;
                }
            }
        }
        if (!sign) {
            message.error('Month must be contacted');
            return;
        }

        downloadTypeVisible.value = true;
    };

    const tabChange = () => {
        doSearch();
    };

    const init = () => {
        doSearch();
    };
    init();
    const isShowPayount = ref(true);
    const payountRoundArray = ref<any[]>([]);

    //根据年季度，查询payoutround
    // const queryPayoutRound = () => {
    //     searchParams.payoutRound = '';
    //     payountRoundArray.value = [];
    //     isShowPayount.value = true;
    //     if (!!searchParams.year && searchParams.quarter.length > 0) {
    //         //进行查询
    //         getPayoutRoundList(searchParams.quarter, searchParams.year).then(
    //             (res) => {
    //                 if (res.length > 0) {
    //                     isShowPayount.value = false;
    //                     payountRoundArray.value = res;
    //                 } else {
    //                     message.error('Payount round data not found');
    //                 }
    //             }
    //         );
    //     }
    // };

    //period初始化
    const openProgramPeriodYear = ref<boolean>(false);
    const handlerOpenProgramPeriodChange = (status: any): void => {
        openProgramPeriodYear.value = status;
    };
    const handlerPanelProgramPeriodChange = (value: string): void => {
        const time = moment(value).format('YYYY');
        searchParams.year = time as string;
        openProgramPeriodYear.value = false;
        searchParams.quarter = [];
        isShowPayount.value = true;
    };

    const selectYear = ref(true);
    watch(
        () => searchParams.year,
        () => {
            selectYear.value = !!searchParams.year;
            if (!searchParams.year) {
                searchParams.quarter = [];
                selectYear.value = false;
            } else {
                selectYear.value = true;
            }
        }
    );

    const handleOk = (data: Array<any>) => {
        //
        console.log(data);
        if (data.length > 1) {
            downloadType.value = 3;
        } else {
            downloadType.value = data[0].sign;
        }
        const array = [];
        if (downloadType.value == 3) {
            array.push(1);
            array.push(2);
        } else {
            array.push(downloadType.value);
        }
        let buname = '';
        for (let i = 0; i < buList.length; i++) {
            if (searchParams.bu === buList[i].id) {
                buname = buList[i].nameEn;
            }
        }

        axios({
            method: 'post',
            url: '/rpapi/payoutForm/report/downloadPayoutForm',
            // url: '/ptapi/payoutForm/report/downloadPayoutForm',
            responseType: 'blob',
            data: {
                brand: searchParams.brand === 'All' ? '' : searchParams.brand,
                bu: buname,
                fileNameList: array,
                // payoutRound: searchParams.payoutRound
                month: searchParams.quarter.sort(),
                year: searchParams.year,
            },
        }).then((res) => {
            // console.log(res.headers['content-disposition'])
            console.log(res);
            // , { type: "application/vnd-excel; char-set=UTF-8" }
            downloadTypeVisible.value = false;
            const name = (res.headers['content-disposition'] as string).split(
                'attachment; filename='
            )[1];
            const blob = new Blob([res.data]); //指定格式为vnd.ms-excel
            const downloadElement = document.createElement('a');
            const href = window.URL.createObjectURL(blob); //创建下载的链接
            downloadElement.href = href;
            downloadElement.download = decodeURI(name);
            document.body.appendChild(downloadElement);
            downloadElement.click(); //点击下载
            document.body.removeChild(downloadElement); //下载完成移除元素
            window.URL.revokeObjectURL(href); //释放掉blob对象
        });
    };

    return {
        downloadTableData,
        brandList,
        downloadType,
        handleOk,
        downloadTypeVisible,
        isShowPayount,
        payountRoundArray,
        selectYear,
        openProgramPeriodYear,
        handlerPanelProgramPeriodChange,
        handlerOpenProgramPeriodChange,
        searchParams,
        activeTab,
        notDemoCarList,
        demoCarList,
        columns,
        searchClick,
        resetClick,
        downloadClick,
        tabChange,
        qmOptions,
        buList,
    };
};
export default useOEMPrepaymentQualified;
