
import { defineComponent, ref, computed, nextTick } from 'vue';
import { useStore } from 'vuex';
import useOEMPayoutForm from '@/hooks/SalesReporting/PayoutForm/useOEMPayoutForm2';
const tableWidth = window.innerWidth;
import { getBrandByBuID } from '@/API/salesReporting';
import DM from '../components/downloadModal.vue';
import { TreeSelect } from 'ant-design-vue';
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
export default defineComponent({
    components: {
        DM,
    },
    setup() {
        const spaceSize = 6;
        const store = useStore();
        const buNameIdMap = computed(() => {
            const buLst = store.state.user.buList.reduce(
                (maps: any, bu: any) => {
                    return { ...maps, [bu.nameCn]: bu.id };
                },
                {}
            );
            return buLst;
        });

        const searchCon = ref();
        const tableHeight = ref();
        nextTick(() => {
            (document.getElementsByClassName(
                'table-box'
            )[0] as any).style.height =
                window.innerHeight -
                220 -
                searchCon.value.$el.scrollHeight +
                'px';
            const height = (document.getElementsByClassName(
                'table-box'
            )[0] as any).style.height;
            const heightNum = parseInt(height.substr(0, height.indexOf('px')));
            tableHeight.value = (heightNum - 110) / 2;
        });

        const {
            handleOk,
            downloadType,
            downloadTypeVisible,
            payountRoundArray,
            selectYear,
            searchParams,
            activeTab,
            notDemoCarList,
            demoCarList,
            columns,
            searchClick,
            resetClick,
            downloadClick,
            tabChange,
            openProgramPeriodYear,
            handlerPanelProgramPeriodChange,
            handlerOpenProgramPeriodChange,
            qmOptions,
            isShowPayount,
            buList,
            brandList,
            downloadTableData,
        } = useOEMPayoutForm();

        const searchBrand = (value: string) => {
            if (!value) {
                return;
            }
            brandList.value = [];
            searchParams.brand = null;
            getBrandByBuID({ buIds: searchParams.bu }).then((res) => {
                const brands = res[0].brandList;
                brands.push({ id: 0, brandNameEn: 'All' });
                brandList.value = brands;
            });
        };

        return {
            spaceSize,
            downloadTableData,
            brandList,
            buList,
            searchBrand,
            isShowPayount,
            selectYear,
            qmOptions,
            searchCon,
            tableWidth,
            tableHeight,
            columns,
            searchParams,
            activeTab,
            notDemoCarList,
            demoCarList,
            searchClick,
            resetClick,
            downloadClick,
            tabChange,
            payountRoundArray,
            openProgramPeriodYear,
            handlerPanelProgramPeriodChange,
            handlerOpenProgramPeriodChange,
            handleOk,
            downloadType,
            downloadTypeVisible,
            SHOW_PARENT,
        };
    },
});
